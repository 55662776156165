import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import Game, { GameStats } from './components/Game';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';


function App() {
  const [searchParams] = useSearchParams();
  const mode = searchParams.get('mode') || 'daily';
  const gameId = searchParams.get('gameId');
  
  const [showStats, setShowStats] = useState(false);
  const [gameStats, setGameStats] = useState<GameStats>({
    streak: 0,
    lastPlayed: '',
    gamesPlayed: 0,
    wordsFound: 0,
    highestScore: 0
  });

  return (
    <div className="bg-gray-50">
      <nav className="bg-white border-b border-gray-200 mb-4">
        <div className="max-w-[440px] mx-auto px-4">
          <div className="flex justify-between items-center h-16">
            <a href="/" className="text-gray-800 text-xl font-semibold">
              Quartiles
            </a>

            <div className="flex space-x-4">
              <a
                href="/?mode=unlimited"
                className="text-gray-700 text-sm px-3 py-1 border border-gray-300 rounded hover:bg-gray-100 transition"
              >
                Unlimited
              </a>
              <button
                onClick={() => setShowStats(true)}
                className="flex items-center text-gray-700 text-sm px-3 py-1 border border-gray-300 rounded hover:bg-gray-100 transition"
              >
                <FontAwesomeIcon icon={faChartBar} />
              </button>
              <a
                href="#how-to-play"
                className="flex items-center text-gray-700 text-sm px-3 py-1 border border-gray-300 rounded hover:bg-gray-100 transition"
              >
                <FontAwesomeIcon icon={faQuestionCircle} />
              </a>
            </div>
          </div>
        </div>
      </nav>

      <Game 
        isDaily={mode === 'daily'} 
        gameId={gameId ? parseInt(gameId) : undefined}
        showStats={showStats}
        setShowStats={setShowStats}
        gameStats={gameStats}
        setGameStats={setGameStats}
      />
    </div>
  );
}

export default App;